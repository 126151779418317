$theme-colors: (
    "primary": #043357,
    "dark": #000000
);


$indigo:  #043357;


$font-family-sans-serif: "Red Hat Display";

$font-size-base:              1rem;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$font-weight-light:           400;
$font-weight-normal:          500;
$font-weight-bold:            700;
$font-weight-bolder:          900;

$display-font-sizes: (
  1: 4rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);


@import "node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap');






.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link img,
footer a, footer a img
{
    transition: all 0.2s ease-in-out 0s;    
    color: rgba(255, 255, 255, 1);
    opacity: 1;
 }



 .navbar-dark .navbar-nav .nav-link:hover, 
 .navbar-dark .navbar-nav .nav-link:focus, 
 .navbar-dark .navbar-nav .nav-link:hover img,
 footer a:hover, footer a:hover img
 {
    opacity: 0.8;
}

div.modal {
    overflow: auto;
  }


.modal-dialog-scrollable {
    overflow-y:auto;
  }


.footer {
    margin-top:3rem;
}

.hero{
    background-color: $indigo;
    background-image: -webkit-linear-gradient(-45deg, #034476 50%, $indigo 50%);    
}

.darkbg{
    background-color: $indigo;
    color: $white;
}

.lightbg{
    background-color: $white;
    color: $black;
}

.slash {
    color: $cyan;
}

.card{
    max-height: 400px;
    max-width: 400px;
    position: relative;
    border: none;
    color: $white;
    overflow:hidden;
    background: linear-gradient(180deg, #30BEFF 0%, #034476 100%);
}

.card-body {
    transition: all 0.5s ease 0s;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: visible;  
    background: rgba(0, 0, 0, .1);
}

.card:hover .card-body {
    display: block;
    background: rgba(0, 0, 0, .7);
    visibility: visible;  
}

.card-img-top {
    transition: all 0.5s ease 0s;
    transform: scale(1.5);
}


.card:hover > .card-img-top {
    transition: all 0.5s ease 0s;
    transform: scale(2);
}

.progress-bar {
    background-color: #38D8FF;
    border-radius: 10px;
    color: #000000;
  }

  .progress {
    background-color: transparent;
    height: 2rem;
    font-size: .75rem;
    font-weight: bold;
    color: #000000;
  }

  a.stretched-link {
    transition: all 0.5s ease-in-out 0s;    
    color: $white;
    text-decoration: none;
    opacity: 0;
  }

  a.stretched-link:hover {
    color: $white;
    text-decoration: none;
    opacity: 1;
}

nav.bg-primary {
    background-color: rgba(51, 170, 51, .1) ;
}

footer a, footer a:hover{
    color: $white;
    text-decoration: none;
}



@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-30px);
		-ms-transform:     translateY(-30px);
		transform:         translateY(-30px)
	}
	60% {
		-webkit-transform: translateY(-15px);
		-ms-transform:     translateY(-15px);
		transform:         translateY(-15px)
	}
}
.scroll-icon {  
  text-align: center;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  height: 20%;
}

.scroll-icon:hover {
  cursor: pointer;
  animation-name: bounce;
}






@media only screen and (hover: none) and (pointer: coarse){

    .card-body {
        transition: none;
        background-color: rgba(0, 0, 0, .5);
        visibility: visible;
    }
    a.stretched-link {
        transition: none;    
        opacity: 1;
        display: block;
        padding: 10px;
      }
  }



@media screen and (max-width:640px) {
  
    .card-body {
        transition: none;
        background-color: rgba(0, 0, 0, .5);
        visibility: visible;
    }
    a.stretched-link {
        transition: none;    
        opacity: 1;
        display: block;
        padding: 10px;
      }
  }


  